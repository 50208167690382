.PageHome .Stats {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.PageHome .Stats h1 {
  font-size: 3vw;
  margin-bottom: 1rem;
}

.PageHome .textContainer {
  font-size: 1rem;
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 2rem;
}

.PageHome .textHeader {
  font-size: 3vw;
  font-weight: 600;
}

.PageHome .LearnMoreBtn {
  width: 8rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.PageHome .spacer {
  height: 5vh;
}

.PageHome .ReviewContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 800px) {
  .PageHome .textHeader {
    font-size: 2rem;
  }

  .PageHome .Stats h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
}

@media (max-width: 600px) {
  .PageHome .textHeader {
    font-size: 2rem;
  }

  .PageHome .Stats div {
    width: 30vw;
  }

  .PageHome .Stats h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  .PageHome .Stats p {
    overflow-wrap: break-word;
  }

  .PageHome .textContainer {
    font-size: 1rem;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
    font-weight: 500;
  }

  .PageHome .spacer {
    height: 3rem;
  }
}