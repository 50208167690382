.PageDonate .Content p {
    width: 45vw;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
    margin-bottom: 2rem;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@media (max-width: 700px) {
    .PageDonate .Content p {
        width: 80vw;
    }
}