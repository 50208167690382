.PageContact .Header {
    font-size: 2rem;
}

.PageContact .spacer {
    height: 5rem;
}

.PageContact .ContactEmails a {
    color: black;
}

.PageContact .FeedbackLink { 
    color: black ;
}

.PageContact .FeedbackHeader {
    font-size: 4rem;
}

.PageContact .EmailDescription {
    font-size: 3rem;
}

@media (max-width: 700px) {
    .PageContact .Header {
        font-size: 1rem;
    }

    .PageContact .FeedbackHeader {
        font-size: 2rem;
    }

    .PageContact .FormEmbed {
        display: none;
    }

    .PageContact .FormContent {
        font-size: 1rem;
    }

    .PageContact .EmailDescription {
        font-size: 2rem;
    }
}