.Footer {
  height: 8rem;
  width: 100%;
}

.Footer .FooterContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  line-height: 1.5;
  padding-bottom: 1rem;
}

.Footer .ContactInfo a {
  color: black;
}

.Footer .CopyrightInfo {
  font-size: 0.8rem;
  color: rgb(124, 124, 124);
}