.PageSchools {
  padding: 2rem;
}

.PageSchools .SessionLogs {
  width: 48vw;
}

.PageSchools .Spacer {
  height: 5rem;
}

.PageSchools .floatClear {
  clear: both;
}

.PageSchools .AltaVistaPoster {
  display: flex;
  margin-right: 2vw;
  float: right;
}

.PageSchools .CarltonPoster {
  display: flex;
  margin-left: 2vw;
  float: left;
}

.PageSchools .AltaVistaPoster img{
  width: 40vw;
}

.PageSchools .CarltonPoster img{
  width: 40vw;
}

.PageSchools .rightHalf {
  float: right;
}

.PageSchools .leftHalf {
  margin-left: 2vw;
  float: left;
}

.PageSchools .SchoolInfo {
  text-align: left;
}

.PageSchools .SchoolInfo h1 {
  font-size: 4vw;
  font-weight: 500;
  margin: 0.1rem;
  overflow-wrap: break-word;
}

.PageSchools .SchoolInfo h2 {
  font-size: 2.5vw;
  font-weight: 500;
}

.PageSchools .SchoolInfo li {
  line-height: 1.6;
  font-weight: 500;
}

@media (max-width: 900px) {
  .PageSchools {
    padding: 4vw;
  }

  .PageSchools .AltaVistaPoster {
    float: unset;
    margin: unset;
  }
  
  .PageSchools .CarltonPoster {
    float: unset;
    margin: unset;
  }

  .PageSchools .rightHalf {
    float: unset;
  }
  
  .PageSchools .leftHalf {
    float: unset;
  }
  
  .PageSchools .SessionLogs {
    width: 80vw;
  }

  .PageSchools .AltaVistaPoster img{
    width: 90vw;
  }
  
  .PageSchools .CarltonPoster img{
    width: 90vw;
  }
  
  .PageSchools .SchoolInfo h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1rem;
    overflow-wrap: break-word;
  }
  
  .PageSchools .SchoolInfo h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .PageSchools .SchoolInfo li {
    line-height: 1.6;
    font-weight: 500;
  }
}