.Header {
  margin-top: 0.735rem;
  background-color: white;
  position: sticky;
  top: -7.25rem;
  z-index: 100;
  border-bottom: 1px solid black;
}

.ImageContainer img {
  width: 12rem;
  margin-bottom: 0.5rem;
}

.ImageContainer img:hover {
  cursor: pointer;
}

.Header .NavigationLinks {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 40%;
}

.Header .NavigationLinks div {
  width: 100%;
}

.Header .NavigationLinks button {
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}

.Header .NavigationLinks h2 {
  font-size: 1rem;
  font-weight: 300;
}

.Header .NavigationLinks button:hover h2 {
  font-size: 1rem;
  font-weight: 300;
  text-decoration: underline;
}

@media (max-width: 800px) {
  .Header .NavigationLinks {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .Header .NavigationLinks h2 {
    font-size: max(3vw, 12px);
    font-weight: 300;
  }
}