.PageAbout {
  padding-top: 3rem;
}

.PageAbout .spacer {
  height: 5rem;
}

.PageAbout .floatClear {
  clear: both;
}

.PageAbout .Image {
  width: 55vw;
  margin: 0;
}

.PageAbout .Header {
  font-size: 2rem;
  font-weight: 600;
}

.PageAbout .HeaderLarge {
  font-size: 4vw;
  font-weight: 500;
  margin-bottom: 1rem;
}

.PageAbout .VolunteerHeader {
  font-size: 3vw;
  font-weight: 500;
  margin-bottom: 1rem;
}

.PageAbout .TextContent {
  width: 30vw;
  text-align: left;
  padding: 0.5rem;
  line-height: 1.5;
}

.PageAbout .AboutText {
  float: right;
  margin-right: 3vw;
}

.PageAbout .spacer {
  height: 5rem;
}

.PageAbout .FounderTable .FounderNames {
  font-size: 2.5rem;
}

.PageAbout .FounderTable .FounderNames td {
  padding: 1rem;
  font-weight: 500;
}

.PageAbout .FounderTable :not(.FounderNames) td {
  padding-left: 3rem;
  padding-right: 3rem;
  line-height: 1.5;
}

.PageAbout .FounderTable {
  width: 80vw;
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
}

.PageAbout .VolunteerTable {
  width: 80vw;
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
}

.PageAbout .VolunteerTable .VolunteerName h1 {
  font-size: 2.5rem;
  font-weight: 550;
}

.PageAbout .VolunteerTable a {
  color: black;
  overflow-wrap: break-word;
}

.PageAbout .VolunteerTable td {
  vertical-align: top;
}

.PageAbout .VolunteerTable .VolunteerStats {
  vertical-align: bottom;
}

.PageAbout .FounderImage {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.PageAbout .StoryIntro {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 850px) {
  .PageAbout .Header {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: unset;
    margin-top: 1rem;
    text-align: left;
  }

  .PageAbout .TextContent {
    width: 98%;
    text-align: left;
    padding: 1rem;
    line-height: 1.5;
  }

  .PageAbout .AboutText {
    float: unset;
  }

  .PageAbout .Image {
    float: left;
    margin: 1rem;
    margin-bottom: unset;
    margin-right: 3rem;
    shape-outside: rectangle();
  }

  .PageAbout .spacer {
    height: 1rem;
  }

  .PageAbout .HeaderLarge {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .PageAbout .VolunteerTable .VolunteerName h1 {
    font-size: 1.5rem;
    font-weight: 550;
  }

  .PageAbout .FounderTable {
    width: 100vw;
  }

  .PageAbout .FounderTable :not(.FounderNames) td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1.5;
    font-size: 10px;
    vertical-align: top;
  }

  .PageAbout .FounderTable .FounderNames td {
    font-size: 1.5rem;
    text-align: center;
  }

  .PageAbout .VolunteerHeader {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .PageAbout .VolunteerTable {
    width: 95vw;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
  }

  .PageAbout .StoryIntro {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .PageAbout .VolunteerStats {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .PageAbout .Header {
    font-size: 2rem;
    font-weight: 600;
    margin: unset;
    text-align: center;
    margin-top: 1rem;
  }

  .PageAbout .TextContent {
    text-align: left;
    padding: 5%;
    padding-top: 0px;
    line-height: 1.5;
    width: 90vw;
  }

  .PageAbout .AboutText {
    float: unset;
  }

  .PageAbout .Image {
    float: unset;
    margin: 1rem;
    margin-bottom: unset;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
}