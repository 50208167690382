.Button {
  width: inherit;
  height: inherit;
  border: none;
  outline: none;
  font-size: 1rem;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, black 50%, rgb(115, 192, 251) 50%);
  color: white;
  font-weight: 200;
  cursor: pointer;
  transition: background-position 0.5s;
}

.Button:hover {
  background-color: rgb(115, 192, 251);
  color: black;
  background-position: -100% 0%;
}