.Collapsible button {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: max(15px, 1vw);
}

.Collapsible button:hover {
  background-color: #ccc;
}

.Collapsible .Content {
  width: 80%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  line-height: 1.5;
}

.Collapsible .Content p {
  text-align: left !important;
}

@media (max-width: 500px) {
  .Collapsible button {
    font-size: 12px;
  }

  .Collapsible .Content {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .Collapsible button {
    font-size: 11px;
  }

  .Collapsible .Content {
    font-size: 11px;
  }
}