.Rating {
  width: 100%;
  height: 24rem;
  margin-top: -4rem;
}

.Rating .ParentName {
  font-size: 1.75rem;
  font-weight: 450;
  margin-top: 1rem;
}

.Rating .StarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
}

.Rating .ProfileImage {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: rgb(176, 176, 176);
  margin-right: auto;
  margin-left: auto;
  position: relative;
  top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
}

.Rating .Content {
  background-color: rgb(239, 239, 239);
  height: 14rem;
  padding-top: 3rem;
  border-radius: 1rem;
}